.gruppe-33 {
  align-items: center;
  display: flex;
  height: 116px;
  justify-content: flex-end;
  left: 0;
  min-width: 490px;
  position: absolute;
  top: 0;
}

.pfad-14-1 {
  align-self: flex-start;
  height: 114px;
  margin-top: 0;
  object-fit: cover;
  width: 84px;
}

.pfad-14-2 {
  height: 113px;
  margin-left: 14px;
  margin-top: 0;
  object-fit: cover;
  width: 68px;
}

.pfad-14-3 {
  height: 113px;
  margin-left: 16px;
  margin-top: 0;
  object-fit: cover;
  width: 94px;
}

.pfad-14-4 {
  align-self: flex-end;
  height: 116px;
  margin-left: 18px;
  object-fit: cover;
  width: 90px;
}

.pfad-1-5 {
  align-self: flex-end;
  height: 114px;
  margin-bottom: 0;
  margin-left: 18px;
  object-fit: cover;
  width: 89px;
}

.gruppe-33.gruppe-34 {
  flex: 1;
  height: unset;
  left: unset;
  min-width: 490.18px;
  opacity: 0.3;
  position: unset;
  top: unset;
}

