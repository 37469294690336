.benutzerdefiniertes-format-1 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 3240px;
  overflow: hidden;
  width: 100%;
}

.overlap-group9 {
  height: 3498px;
  margin-left: -412px;
  margin-top: -242px;
  position: relative;
  width: 100%;
}

.overlap-group8 {
  height: 1375px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.rechteck-21 {
  background: linear-gradient(180deg, #1900bb 0%, #05031e 100%);
  height: 1080px;
  left: 412px;
  position: absolute;
  top: 242px;
  width: 100%;
}

.rechteck-21 video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.rechteck-21 img {
  object-fit: cover;
}

.rechteck-22 {
  background: linear-gradient(180deg, #0d0081 0%, #821de8 5.02%, #212192 50.63%, #9c1cff 92.05%, #b810ff 100%);
  height: 99px;
  left: 412px;
  position: absolute;
  top: 231px;
  width: 1920px;
}

.ellipse-5 {
  border-radius: 390.5px/292px;
  height: 584px;
  left: 1914px;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 781px;
}

.ellipse-3 {
  border-radius: 390.5px/292px;
  height: 584px;
  left: 974px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 781px;
}

.rechteck-18 {
  background: linear-gradient(92.1deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.15) 99.97%);
  border: 2px solid;
  border-color: #ffffff38;
  border-radius: 20px;
  box-shadow: 0px 5px 2px #00000029;
  height: 72px;
  left: 552px;
  position: absolute;
  top: 257px;
  width: 1640px;
}

.telegram {
  height: 60px;
  left: 1895px;
  object-fit: cover;
  position: absolute;
  top: 267px;
  width: 51px;
}

.discord {
  height: 59px;
  left: 1960px;
  object-fit: cover;
  position: absolute;
  top: 268px;
  width: 51px;
}

.telegram-1 {
  height: 63px;
  left: 2025px;
  object-fit: cover;
  position: absolute;
  top: 266px;
  width: 47px;
}

.about {
  left: 764px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 283px;
  white-space: nowrap;
}

.team {
  left: 1183px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 283px;
  white-space: nowrap;
}

.rechteck-1 {
  border: 2px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 5px 2px #00000029;
  height: 32px;
  left: 1553px;
  position: absolute;
  top: 277px;
  width: 123px;
}

.rechteck-2 {
  border: 2px solid;
  border-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 5px 2px #00000029;
  height: 32px;
  left: 1711px;
  position: absolute;
  top: 277px;
  width: 123px;
}

.claim {
  left: 1590px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 285px;
  white-space: nowrap;
}

.connect {
  left: 1733px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 285px;
  white-space: nowrap;
}

.ellipse-1 {
  border-radius: 711px/559px;
  height: 1118px;
  left: 193px;
  object-fit: cover;
  position: absolute;
  top: 257px;
  width: 1422px;
}

.ellipse-2 {
  border-radius: 466.5px/461.5px;
  height: 923px;
  left: 508px;
  object-fit: cover;
  position: absolute;
  top: 355px;
  width: 933px;
}

.all {
  cursor: pointer;
  height: 868px;
  left: 1274px;
  object-fit: cover;
  position: absolute;
  top: 408px;
  transition: all 0.2s ease;
  width: 1008px;
}

.all:hover {
  transform: scale(1.1);
}

.unbenannt-2 {
  display: flex;
  height: 187px;
  left: 663px;
  position: absolute;
  top: 551px;
  width: 539px;
}

.ebene-1 {
  display: flex;
  flex: 1;
  width: 539px;
}

.gruppe-37 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 539px;
}

.overlap-group3 {
  height: 187px;
  position: relative;
  width: 539px;
}

.gruppe-18 {
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 187px;
  left: 0;
  position: absolute;
  top: 0;
  width: 539px;
}

.gruppe-17 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  height: 143.25px;
  margin-left: 21.6px;
  margin-right: 21.5px;
  margin-top: 14.7px;
  min-width: calc(100.00% - 43px);
}

.gruppe-container {
  height: 143px;
  margin-bottom: 0;
  position: relative;
  width: 496px;
}

.gruppe-1 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 14px;
  min-width: 467px;
  position: absolute;
  top: 22px;
}

.pfad-1-2 {
  align-self: flex-start;
  height: 120px;
  object-fit: cover;
  width: 84px;
}

.pfad-2-1 {
  height: 119px;
  margin-bottom: 0;
  margin-left: 8px;
  object-fit: cover;
  width: 69px;
}

.pfad {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 10px;
  object-fit: cover;
  width: 94px;
}

.pfad-4-2 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 90px;
}

.pfad-1 {
  align-self: flex-end;
  height: 120px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 90px;
}

.gruppe-2 {
  align-items: center;
  display: flex;
  height: 122px;
  justify-content: flex-end;
  left: 14px;
  min-width: 469px;
  position: absolute;
  top: 20px;
}

.pfad-6-2 {
  align-self: flex-start;
  height: 120px;
  margin-top: 0;
  object-fit: cover;
  width: 84px;
}

.pfad-7-1 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 8px;
  object-fit: cover;
  width: 69px;
}

.pfad-2 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 91px;
}

.gruppe-3 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 13px;
  min-width: 471px;
  position: absolute;
  top: 19px;
}

.pfad-3 {
  align-self: flex-start;
  height: 120px;
  margin-top: 0;
  object-fit: cover;
  width: 85px;
}

.pfad-4 {
  height: 119px;
  margin-bottom: 0;
  margin-left: 8px;
  object-fit: cover;
  width: 70px;
}

.gruppe-4 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 12px;
  min-width: 473px;
  position: absolute;
  top: 17px;
}

.pfad-16-1 {
  align-self: flex-start;
  height: 120px;
  object-fit: cover;
  width: 85px;
}

.pfad-17-1 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 8px;
  object-fit: cover;
  width: 70px;
}

.pfad-5 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 10px;
  object-fit: cover;
  width: 95px;
}

.pfad-6 {
  align-self: flex-end;
  height: 120px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 91px;
}

.gruppe-5 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 11px;
  min-width: 475px;
  position: absolute;
  top: 16px;
}

.pfad-24-1 {
  height: 122px;
  margin-left: 12px;
  margin-top: 0;
  object-fit: cover;
  width: 92px;
}

.gruppe-6 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 10px;
  min-width: 476px;
  position: absolute;
  top: 14px;
}

.pfad-7 {
  align-self: flex-start;
  height: 120px;
  margin-top: 0;
  object-fit: cover;
  width: 86px;
}

.pfad-8 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 8px;
  object-fit: cover;
  width: 71px;
}

.pfad-9 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 92px;
}

.gruppe-7 {
  align-items: center;
  display: flex;
  height: 122px;
  justify-content: flex-end;
  left: 9px;
  min-width: 478px;
  position: absolute;
  top: 13px;
}

.pfad-3-1 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 10px;
  object-fit: cover;
  width: 96px;
}

.pfad-10 {
  align-self: flex-end;
  height: 120px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 92px;
}

.gruppe-8 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 8px;
  min-width: 480px;
  position: absolute;
  top: 11px;
}

.pfad-37 {
  height: 119px;
  margin-bottom: 0;
  margin-left: 8px;
  object-fit: cover;
  width: 71px;
}

.pfad-11 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 93px;
}

.gruppe-9 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 7px;
  min-width: 482px;
  position: absolute;
  top: 10px;
}

.pfad-4-1 {
  align-self: flex-start;
  height: 120px;
  margin-top: 0;
  object-fit: cover;
  width: 87px;
}

.pfad-12 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 10px;
  object-fit: cover;
  width: 97px;
}

.pfad-13 {
  align-self: flex-end;
  height: 120px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 93px;
}

.gruppe-10 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 6px;
  min-width: 484px;
  position: absolute;
  top: 9px;
}

.pfad-14 {
  height: 119px;
  margin-bottom: 0;
  margin-left: 8px;
  object-fit: cover;
  width: 72px;
}

.pfad-49 {
  height: 122px;
  margin-left: 12px;
  margin-top: 0;
  object-fit: cover;
  width: 94px;
}

.gruppe-11 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 5px;
  min-width: 486px;
  position: absolute;
  top: 7px;
}

.pfad-15 {
  align-self: flex-start;
  height: 120px;
  margin-top: 0;
  object-fit: cover;
  width: 88px;
}

.pfad-52 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 8px;
  object-fit: cover;
  width: 72px;
}

.pfad-5-1 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 94px;
}

.gruppe-12 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 4px;
  min-width: 488px;
  position: absolute;
  top: 6px;
}

.pfad-16 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 10px;
  object-fit: cover;
  width: 98px;
}

.pfad-17 {
  align-self: flex-end;
  height: 120px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 94px;
}

.gruppe-13 {
  align-items: center;
  display: flex;
  height: 122px;
  justify-content: flex-end;
  left: 3px;
  min-width: 490px;
  position: absolute;
  top: 4px;
}

.pfad-18 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 8px;
  object-fit: cover;
  width: 73px;
}

.pfad-6-1 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 95px;
}

.gruppe-14 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 2px;
  min-width: 492px;
  position: absolute;
  top: 3px;
}

.pfad-19 {
  align-self: flex-start;
  height: 120px;
  margin-top: 0;
  object-fit: cover;
  width: 89px;
}

.pfad-20 {
  height: 119px;
  margin-bottom: 0.01px;
  margin-left: 10px;
  object-fit: cover;
  width: 99px;
}

.gruppe-15 {
  align-items: center;
  display: flex;
  height: 122px;
  justify-content: flex-end;
  left: 1px;
  min-width: 494px;
  position: absolute;
  top: 1px;
}

.pfad-72 {
  height: 119px;
  margin-bottom: 0;
  margin-left: 8px;
  object-fit: cover;
  width: 73px;
}

.pfad-74 {
  height: 122px;
  margin-left: 12px;
  margin-top: 0;
  object-fit: cover;
  width: 95px;
}

.pfad-21 {
  align-self: flex-end;
  height: 120px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 95px;
}

.gruppe-16 {
  align-items: center;
  display: flex;
  height: 122px;
  left: 0;
  min-width: 496px;
  position: absolute;
  top: 0;
}

.pfad-76 {
  align-self: flex-start;
  height: 120px;
  object-fit: cover;
  width: 89px;
}

.pfad-79 {
  align-self: flex-end;
  height: 122px;
  margin-bottom: 0;
  margin-left: 12px;
  object-fit: cover;
  width: 96px;
}

.gruppe-container-1 {
  height: 127px;
  left: 23px;
  position: absolute;
  top: 16px;
  width: 493px;
}

.gruppe-19 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 5px;
  min-width: 483px;
  position: absolute;
  top: 9px;
}

.pfad-22 {
  align-self: flex-start;
  height: 117px;
  margin-top: 0;
  object-fit: cover;
  width: 85px;
}

.pfad-82 {
  height: 116px;
  margin-bottom: 0;
  margin-left: 11px;
  object-fit: cover;
  width: 69px;
}

.pfad-23 {
  height: 116px;
  margin-left: 13px;
  margin-top: 0;
  object-fit: cover;
  width: 95px;
}

.pfad-8-1 {
  height: 119px;
  margin-left: 15px;
  object-fit: cover;
  width: 91px;
}

.pfad-85 {
  align-self: flex-end;
  height: 117px;
  margin-bottom: 0;
  margin-left: 15px;
  object-fit: cover;
  width: 90px;
}

.gruppe-20 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 4px;
  min-width: 484px;
  position: absolute;
  top: 8px;
}

.pfad-24 {
  align-self: flex-start;
  height: 117px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 85px;
}

.pfad-87 {
  height: 116px;
  margin-left: 11px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 69px;
}

.pfad-25 {
  height: 116px;
  margin-left: 13px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 95px;
}

.pfad-26 {
  align-self: flex-end;
  height: 117px;
  margin-bottom: 0;
  margin-left: 15px;
  object-fit: cover;
  width: 91px;
}

.gruppe-21 {
  align-items: flex-start;
  display: flex;
  height: 119px;
  left: 4px;
  min-width: 485px;
  position: absolute;
  top: 7px;
}

.pfad-91 {
  height: 117px;
  margin-top: 0;
  object-fit: cover;
  width: 85px;
}

.pfad-92 {
  align-self: center;
  height: 116px;
  margin-bottom: 0;
  margin-left: 11px;
  object-fit: cover;
  width: 69px;
}

.pfad-27 {
  align-self: center;
  height: 116px;
  margin-left: 13px;
  margin-top: 0;
  object-fit: cover;
  width: 95px;
}

.pfad-94 {
  height: 119px;
  margin-left: 15px;
  margin-top: 0;
  object-fit: cover;
  width: 91px;
}

.gruppe-22 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 3px;
  min-width: 486px;
  position: absolute;
  top: 6px;
}

.pfad-97 {
  height: 116px;
  margin-left: 11px;
  object-fit: cover;
  width: 70px;
}

.pfad-28 {
  height: 119px;
  margin-left: 15px;
  object-fit: cover;
  width: 92px;
}

.gruppe-23 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 3px;
  min-width: 487px;
  position: absolute;
  top: 5px;
}

.pfad-102 {
  height: 116px;
  margin-left: 11px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 70px;
}

.gruppe-24 {
  align-items: flex-start;
  display: flex;
  height: 119px;
  left: 2px;
  min-width: 488px;
  position: absolute;
  top: 4px;
}

.pfad-106 {
  height: 117px;
  margin-top: 0;
  object-fit: cover;
  width: 86px;
}

.pfad-107 {
  align-self: center;
  height: 116px;
  margin-bottom: 0;
  margin-left: 11px;
  object-fit: cover;
  width: 70px;
}

.pfad-109 {
  height: 119px;
  margin-left: 15px;
  margin-top: 0;
  object-fit: cover;
  width: 92px;
}

.gruppe-27 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 1px;
  min-width: 491px;
  position: absolute;
  top: 2px;
}

.pfad-12-1 {
  align-self: flex-start;
  height: 117px;
  margin-top: 0;
  object-fit: cover;
  width: 86px;
}

.pfad-122 {
  height: 116px;
  margin-bottom: 0;
  margin-left: 11px;
  object-fit: cover;
  width: 70px;
}

.pfad-12-2 {
  height: 116px;
  margin-left: 13px;
  margin-top: 0;
  object-fit: cover;
  width: 96px;
}

.pfad-12-3 {
  height: 119px;
  margin-left: 15px;
  object-fit: cover;
  width: 93px;
}

.pfad-1-1 {
  align-self: flex-end;
  height: 117px;
  margin-bottom: 0;
  margin-left: 15px;
  object-fit: cover;
  width: 92px;
}

.gruppe-28 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 0;
  min-width: 492px;
  position: absolute;
  top: 1px;
}

.pfad-127 {
  height: 116px;
  margin-left: 11px;
  margin-top: 0;
  object-fit: cover;
  width: 70px;
}

.gruppe-32 {
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 137px;
  left: 16px;
  position: absolute;
  top: 13px;
  width: 511px;
}

.gruppe-container-2 {
  height: 116px;
  left: 24px;
  position: absolute;
  top: 18px;
  width: 490px;
}

.gruppe-35 {
  display: flex;
  height: 116px;
  left: 0;
  position: absolute;
  top: 0;
  width: 490px;
}

.logo-updated {
  height: 78px;
  left: 1334px;
  object-fit: cover;
  position: absolute;
  top: 259px;
  width: 76px;
}

.place {
  left: 643px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 283px;
  white-space: nowrap;
}

.roadmap {
  left: 891px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 283px;
  white-space: nowrap;
}

.how-to {
  left: 1044px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-shadow: 0px 5px 2px #00000029;
  top: 283px;
  white-space: nowrap;
}

.icon-instagram {
  height: 61px;
  left: 2086px;
  object-fit: cover;
  position: absolute;
  top: 267px;
  width: 56px;
}

.overlap-group5 {
  height: 338px;
  left: 602px;
  position: absolute;
  top: 755px;
  width: 650px;
}

.gruppe-48 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 124px;
  left: 0;
  min-width: 650px;
  padding: 3.8px 5.2px;
  position: absolute;
  top: 0;
}

.overlap-group1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 115px;
  position: relative;
  width: 636px;
}

.pfad-181 {
  height: 77px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 28px;
  width: 26px;
}

.pfad-182 {
  height: 8px;
  left: 219px;
  object-fit: cover;
  position: absolute;
  top: 52px;
  width: 16px;
}

.pfad-183 {
  height: 25px;
  left: 54px;
  object-fit: cover;
  position: absolute;
  top: 91px;
  width: 529px;
}

.pfad-184 {
  height: 10px;
  left: 359px;
  object-fit: cover;
  position: absolute;
  top: 37px;
  width: 21px;
}

.gruppe-45 {
  align-items: center;
  display: flex;
  height: 9px;
  left: 358px;
  min-width: 19px;
  position: absolute;
  top: 37px;
}

.pfad-187 {
  height: 9px;
  object-fit: cover;
  width: 18px;
}

.overlap-group {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3.31px;
  min-width: 1px;
}

.pfad-186 {
  height: 1px;
  margin-bottom: -0.03px;
  object-fit: cover;
  width: 1px;
}

.pfad-188 {
  height: 31px;
  left: 608px;
  object-fit: cover;
  position: absolute;
  top: 46px;
  width: 28px;
}

.pfad-189 {
  height: 87px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 591px;
}

.pfad-190 {
  height: 3px;
  left: 159px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 228px;
}

.pfad-191 {
  height: 3px;
  left: 190px;
  object-fit: cover;
  position: absolute;
  top: 91px;
  width: 215px;
}

.pfad-192 {
  height: 50px;
  left: 17px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 93px;
}

.pfad-193 {
  height: 22px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 80px;
  width: 104px;
}

.pfad-194 {
  height: 42px;
  left: 38px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 268px;
}

.gruppe-46 {
  background-position: 50% 50%;
  background-size: cover;
  height: 115px;
  left: 0;
  mix-blend-mode: overlay;
  position: absolute;
  top: 0;
  width: 353px;
}

.pfad-196 {
  height: 11px;
  left: 312px;
  object-fit: cover;
  position: absolute;
  top: 62px;
  width: 20px;
}

.gruppe-47 {
  background-position: 50% 50%;
  background-size: cover;
  height: 97px;
  left: 455px;
  mix-blend-mode: overlay;
  position: absolute;
  top: 3px;
  width: 132px;
}

.pfad-198 {
  height: 40px;
  left: 465px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 19px;
}

.pfad-199 {
  height: 25px;
  left: 122px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 57px;
}

.pfad-200 {
  height: 67px;
  left: 608px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 28px;
}

.pfad-201 {
  height: 36px;
  left: 432px;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 181px;
}

.pfad-202 {
  height: 38px;
  left: 513px;
  object-fit: cover;
  position: absolute;
  top: 64px;
  width: 95px;
}

.pfad-203 {
  height: 34px;
  left: 279px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 18px;
}

.pfad-204 {
  height: 40px;
  left: 276px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 18px;
}

.pfad-205 {
  height: 17px;
  left: 111px;
  object-fit: cover;
  position: absolute;
  top: 58px;
  width: 29px;
}

.pfad-206 {
  height: 29px;
  left: 558px;
  object-fit: cover;
  position: absolute;
  top: 32px;
  width: 8px;
}

.pfad-207 {
  height: 8px;
  left: 322px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 10px;
}

.gruppe-52 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 124px;
  justify-content: flex-end;
  left: 14px;
  min-width: 627px;
  padding: 2.4px 6.4px;
  position: absolute;
  top: 107px;
}

.overlap-group2 {
  background-position: 50% 50%;
  background-size: cover;
  height: 119px;
  position: relative;
  width: 612px;
}

.pfad-210 {
  height: 50px;
  left: 44px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 203px;
}

.pfad-211 {
  height: 78px;
  left: 576px;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 37px;
}

.pfad-212 {
  height: 8px;
  left: 386px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 16px;
}

.pfad-213 {
  height: 27px;
  left: 35px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 543px;
}

.pfad-214 {
  height: 10px;
  left: 248px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 19px;
}

.pfad-container {
  height: 9px;
  left: 250px;
  position: absolute;
  top: 62px;
  width: 18px;
}

.pfad-215 {
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 1px;
}

.pfad-216 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 1px;
}

.pfad-217 {
  height: 9px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 17px;
}

.pfad-218 {
  height: 33px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 29px;
}

.pfad-219 {
  height: 95px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 576px;
}

.pfad-220 {
  height: 10px;
  left: 243px;
  object-fit: cover;
  position: absolute;
  top: 91px;
  width: 206px;
}

.pfad-221 {
  height: 6px;
  left: 218px;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 218px;
}

.pfad-222 {
  height: 41px;
  left: 494px;
  object-fit: cover;
  position: absolute;
  top: 70px;
  width: 98px;
}

.pfad-223 {
  height: 34px;
  left: 500px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 96px;
}

.pfad-224 {
  height: 41px;
  left: 317px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 255px;
}

.gruppe-50 {
  background-position: 50% 50%;
  background-size: cover;
  height: 119px;
  left: 275px;
  mix-blend-mode: overlay;
  position: absolute;
  top: 0;
  width: 337px;
}

.pfad-226 {
  height: 12px;
  left: 207px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 19px;
}

.gruppe-51 {
  background-position: 50% 50%;
  background-size: cover;
  height: 99px;
  left: 67px;
  mix-blend-mode: overlay;
  position: absolute;
  top: 20px;
  width: 117px;
}

.pfad-228 {
  height: 39px;
  left: 147px;
  object-fit: cover;
  position: absolute;
  top: 56px;
  width: 26px;
}

.pfad-229 {
  height: 68px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 38px;
  width: 34px;
}

.pfad-230 {
  height: 27px;
  left: 32px;
  object-fit: cover;
  position: absolute;
  top: 79px;
  width: 171px;
}

.pfad-231 {
  height: 49px;
  left: 17px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 91px;
}

.pfad-232 {
  height: 35px;
  left: 326px;
  object-fit: cover;
  position: absolute;
  top: 52px;
  width: 18px;
}

.pfad-233 {
  height: 42px;
  left: 329px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 18px;
}

.pfad-234 {
  height: 16px;
  left: 480px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  width: 30px;
}

.pfad-235 {
  height: 30px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 9px;
}

.pfad-236 {
  height: 9px;
  left: 206px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 10px;
}

.overlap-group3-1 {
  height: 120px;
  left: 57px;
  position: absolute;
  top: 217px;
  width: 541px;
}

.gruppe-53 {
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 541px;
}

.pfad-238 {
  flex: 1;
  margin-bottom: 5.7px;
  margin-left: 7.6px;
  margin-right: 7.8px;
  margin-top: 5.2px;
  object-fit: cover;
  width: 525.85px;
}

.pfad-239 {
  height: 23px;
  left: 55px;
  object-fit: cover;
  position: absolute;
  top: 91px;
  width: 434px;
}

.pfad-240 {
  height: 64px;
  left: 504px;
  object-fit: cover;
  position: absolute;
  top: 34px;
  width: 29px;
}

.pfad-241 {
  height: 44px;
  left: 241px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 271px;
}

.pfad-242 {
  height: 32px;
  left: 187px;
  object-fit: cover;
  position: absolute;
  top: 70px;
  width: 319px;
}

.pfad-243 {
  height: 58px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 41px;
  width: 31px;
}

.pfad-244 {
  height: 57px;
  left: 32px;
  object-fit: cover;
  position: absolute;
  top: 23px;
  width: 45px;
}

.pfad-245 {
  height: 18px;
  left: 40px;
  object-fit: cover;
  position: absolute;
  top: 86px;
  width: 109px;
}

.pfad-246 {
  height: 75px;
  left: 64px;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 427px;
}

.pfad-247 {
  height: 109px;
  left: 8px;
  mix-blend-mode: overlay;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 283px;
}

.pfad-248 {
  height: 107px;
  left: 341px;
  mix-blend-mode: overlay;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 193px;
}

.pfad-249 {
  height: 49px;
  left: 181px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 32px;
}

.pfad-250 {
  height: 51px;
  left: 340px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 38px;
}

.pfad-251 {
  height: 8px;
  left: 260px;
  object-fit: cover;
  position: absolute;
  top: 43px;
  width: 14px;
}

.pfad-252 {
  height: 10px;
  left: 470px;
  object-fit: cover;
  position: absolute;
  top: 45px;
  width: 22px;
}

.pfad-253 {
  height: 7px;
  left: 204px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 18px;
}

.pfad-254 {
  height: 7px;
  left: 204px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 11px;
}

.pfad-container-1 {
  height: 283px;
  left: 79px;
  position: absolute;
  top: 32px;
  width: 484px;
}

.pfad-255 {
  height: 282px;
  left: 65px;
  mix-blend-mode: multiply;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 419px;
}

.pfad-256 {
  height: 273px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 484px;
}

.pfad-257 {
  height: 34px;
  left: 413px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 70px;
}

.pfad-258 {
  height: 22px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 44px;
}

.pfad-259 {
  height: 273px;
  left: 0;
  mix-blend-mode: overlay;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 360px;
}

.pengu-combines-a-def {
  left: 713px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 5px 2px #00000029;
  top: 837px;
  width: 422px;
}

.overlap-group6 {
  height: 49px;
  left: 758px;
  position: absolute;
  top: 992px;
  width: 150px;
}

.gruppe-41 {
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 49px;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

.rechteck-20 {
  background-color: var(--blizzard-blue);
  border-radius: 12.41px 12.41px 12.41px 12.41px;
  flex: 1;
  margin-bottom: 3.0px;
  margin-left: 2.3px;
  margin-right: 2.5px;
  margin-top: 2.4px;
  width: 145.6px;
}

.pfad-162 {
  height: 6px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 40px;
  width: 127px;
}

.pfad-163 {
  height: 28px;
  left: 142px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 6px;
}

.pfad-164 {
  height: 14px;
  left: 67px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 75px;
}

.pfad-165 {
  height: 16px;
  left: 51px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 92px;
}

.pfad-166 {
  height: 25px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 8px;
}

.pfad-167 {
  height: 23px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 13px;
}

.pfad-168 {
  height: 9px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 31px;
}

.pfad-169 {
  height: 30px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 122px;
}

.pfad-170 {
  height: 43px;
  left: 2px;
  mix-blend-mode: overlay;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 78px;
}

.pfad-171 {
  height: 43px;
  left: 95px;
  mix-blend-mode: overlay;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 53px;
}

.pfad-172 {
  height: 21px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 10px;
}

.pfad-173 {
  height: 22px;
  left: 95px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 11px;
}

.pfad-174 {
  height: 4px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 4px;
}

.pfad-175 {
  height: 4px;
  left: 131px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 6px;
}

.pfad-176 {
  height: 3px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 5px;
}

.pfad-177 {
  height: 3px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 3px;
}

.overlap-group7 {
  height: 49px;
  left: 932px;
  position: absolute;
  top: 992px;
  width: 150px;
}

.buy {
  -webkit-text-stroke: 1px var(--navy-blue);
  left: 808px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 1004px;
  white-space: nowrap;
}

.how-to-1 {
  -webkit-text-stroke: 1px var(--navy-blue);
  left: 956px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 1004px;
  white-space: nowrap;
}

.ellipse-4 {
  border-radius: 390.5px/292px;
  height: 584px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 781px;
}

.overlap-group9-item {
  height: 1080px;
  left: 412px;
  object-fit: cover;
  position: absolute;
  top: 1321px;
  width: 1920px;
}

.bg-pengu-2-aurora {
  height: 1080px;
  left: 412px;
  object-fit: cover;
  position: absolute;
  top: 1320px;
  width: 1920px;
}

.rechteck-23 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  background: linear-gradient(114.15deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 0.2px solid;
  border-color: var(--white);
  border-radius: 27px;
  height: 364px;
  left: 552px;
  position: absolute;
  top: 1455px;
  width: 812px;
}

.pengu-combines-a-def-1 {
  height: 221px;
  left: 594px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: 1570px;
  width: 735px;
}

.x5 {
  left: 594px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: 1376px;
  white-space: nowrap;
}

.title {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 600;
  left: 594px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 1498px;
  white-space: nowrap;
}

.rechteck-24 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  background: linear-gradient(115.87deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 0.2px solid;
  border-color: var(--white);
  border-radius: 27px;
  height: 193px;
  left: 552px;
  position: absolute;
  top: 1838px;
  width: 398px;
}

.rechteck-25 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  background: linear-gradient(115.87deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 0.2px solid;
  border-color: var(--white);
  border-radius: 27px;
  height: 193px;
  left: 966px;
  position: absolute;
  top: 1838px;
  width: 398px;
}

.rechteck-26 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  background: linear-gradient(115.87deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 0.2px solid;
  border-color: var(--white);
  border-radius: 27px;
  height: 193px;
  left: 552px;
  position: absolute;
  top: 2050px;
  width: 398px;
}

.rechteck-27 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  background: linear-gradient(115.87deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  border: 0.2px solid;
  border-color: var(--white);
  border-radius: 27px;
  height: 193px;
  left: 966px;
  position: absolute;
  top: 2050px;
  width: 398px;
}

.x015-of-the-transac {
  height: 148px;
  left: 581px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: 1861px;
  width: 342px;
}

.x015-of-the-transac-1 {
  height: 148px;
  left: 995px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: 1861px;
  width: 342px;
}

.x010-of-the-transac {
  height: 148px;
  left: 581px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: 2078px;
  width: 342px;
}

.x010-of-the-transac-1 {
  height: 148px;
  left: 995px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: 2074px;
  width: 342px;
}

.igloo-1 {
  height: 832px;
  left: 853px;
  object-fit: cover;
  position: absolute;
  top: 1474px;
  width: 1479px;
}

.all-1 {
  height: 736px;
  left: 1177px;
  object-fit: cover;
  position: absolute;
  top: 1546px;
  width: 968px;
}

.bg-pengu-3-bg {
  height: 1098px;
  left: 396px;
  object-fit: cover;
  position: absolute;
  top: 2400px;
  width: 1920px;
}

.bg-pengu-3-iceberg {
  height: 1080px;
  left: 412px;
  object-fit: cover;
  position: absolute;
  top: 2400px;
  width: 1920px;
}

