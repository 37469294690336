.gruppe-25 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 2px;
  min-width: 489px;
  position: absolute;
  top: 3px;
}

.pfad-111 {
  align-self: flex-start;
  height: 117px;
  margin-top: 0;
  object-fit: cover;
  width: 86px;
}

.pfad-112 {
  height: 116px;
  margin-left: 11px;
  margin-top: 0;
  object-fit: cover;
  width: 70px;
}

.pfad-113 {
  height: 116px;
  margin-left: 13px;
  margin-top: 0;
  object-fit: cover;
  width: 96px;
}

.pfad-11-1 {
  height: 119px;
  margin-left: 15px;
  object-fit: cover;
  width: 92px;
}

.pfad-1-3 {
  align-self: flex-end;
  height: 117px;
  margin-bottom: 0;
  margin-left: 15px;
  object-fit: cover;
  width: 92px;
}

.gruppe-25.gruppe-26 {
  justify-content: flex-end;
  left: 1px;
  min-width: 490px;
}

.gruppe-25.gruppe-26 .pfad-111,
.gruppe-25.gruppe-26 .pfad-112,
.gruppe-25.gruppe-26 .pfad-113 {
  margin-top: 0.01px;
}

