.gruppe-29 {
  align-items: center;
  display: flex;
  height: 119px;
  left: 0;
  min-width: 493px;
  position: absolute;
  top: 0;
}

.pfad-13-1 {
  align-self: flex-start;
  height: 117px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 86px;
}

.pfad-13-2 {
  height: 116px;
  margin-left: 11px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 71px;
}

.pfad-13-3 {
  height: 116px;
  margin-left: 13px;
  margin-top: 0.01px;
  object-fit: cover;
  width: 96px;
}

.pfad-13-4 {
  height: 119px;
  margin-left: 15px;
  object-fit: cover;
  width: 93px;
}

.pfad-1-4 {
  align-self: flex-end;
  height: 117px;
  margin-left: 15px;
  object-fit: cover;
  width: 92px;
}

.gruppe-29.gruppe-31 {
  flex: 1;
  height: unset;
  left: unset;
  margin-bottom: 14.5px;
  margin-left: 7.3px;
  margin-right: 10.2px;
  margin-top: 3.2px;
  min-width: 493.05px;
  position: unset;
  top: unset;
}

