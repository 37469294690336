:root { 
  --black: #000000;
  --blizzard-blue: #b5e5ee;
  --navy-blue: #1c75bc;
  --white: #ffffff;
 
  --font-size-l: 25px;
  --font-size-m: 17px;
  --font-size-xl: 40px;
 
  --font-family-open_sans: "Open Sans", Helvetica;
  --font-family-segoeui_variable_text-bold: "SegoeUI Variable Text-Bold", Helvetica;
}
.opensans-normal-white-17px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.opensans-normal-black-17px {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.segoeuivariabletext-bold-white-25px {
  color: var(--white);
  font-family: var(--font-family-segoeui_variable_text-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
