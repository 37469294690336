@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Open+Sans:400,600";
@import "https://px.animaapp.com/63323171199092d846e1d567.6332317275026a793051e570.2jGST1G.rcp.png";
.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --black: #000;
  --blizzard-blue: #b5e5ee;
  --navy-blue: #1c75bc;
  --white: #fff;
  --font-size-l: 25px;
  --font-size-m: 17px;
  --font-size-xl: 40px;
  --font-family-open_sans: "Open Sans", Helvetica;
  --font-family-segoeui_variable_text-bold: "SegoeUI Variable Text-Bold", Helvetica;
}

.opensans-normal-white-17px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.opensans-normal-black-17px {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.segoeuivariabletext-bold-white-25px {
  color: var(--white);
  font-family: var(--font-family-segoeui_variable_text-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}



.gruppe-25 {
  height: 119px;
  min-width: 489px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 3px;
  left: 2px;
}

.pfad-111 {
  height: 117px;
  object-fit: cover;
  width: 86px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-112 {
  height: 116px;
  object-fit: cover;
  width: 70px;
  margin-top: 0;
  margin-left: 11px;
}

.pfad-113 {
  height: 116px;
  object-fit: cover;
  width: 96px;
  margin-top: 0;
  margin-left: 13px;
}

.pfad-11-1 {
  height: 119px;
  object-fit: cover;
  width: 92px;
  margin-left: 15px;
}

.pfad-1-3 {
  height: 117px;
  object-fit: cover;
  width: 92px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 15px;
}

.gruppe-25.gruppe-26 {
  min-width: 490px;
  justify-content: flex-end;
  left: 1px;
}

.gruppe-25.gruppe-26 .pfad-111, .gruppe-25.gruppe-26 .pfad-112, .gruppe-25.gruppe-26 .pfad-113 {
  margin-top: .01px;
}

.gruppe-29 {
  height: 119px;
  min-width: 493px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pfad-13-1 {
  height: 117px;
  object-fit: cover;
  width: 86px;
  align-self: flex-start;
  margin-top: .01px;
}

.pfad-13-2 {
  height: 116px;
  object-fit: cover;
  width: 71px;
  margin-top: .01px;
  margin-left: 11px;
}

.pfad-13-3 {
  height: 116px;
  object-fit: cover;
  width: 96px;
  margin-top: .01px;
  margin-left: 13px;
}

.pfad-13-4 {
  height: 119px;
  object-fit: cover;
  width: 93px;
  margin-left: 15px;
}

.pfad-1-4 {
  height: 117px;
  object-fit: cover;
  width: 92px;
  align-self: flex-end;
  margin-left: 15px;
}

.gruppe-29.gruppe-31 {
  height: unset;
  left: unset;
  min-width: 493.05px;
  position: unset;
  top: unset;
  flex: 1;
  margin: 3.2px 10.2px 14.5px 7.3px;
}

.gruppe-33 {
  height: 116px;
  min-width: 490px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pfad-14-1 {
  height: 114px;
  object-fit: cover;
  width: 84px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-14-2 {
  height: 113px;
  object-fit: cover;
  width: 68px;
  margin-top: 0;
  margin-left: 14px;
}

.pfad-14-3 {
  height: 113px;
  object-fit: cover;
  width: 94px;
  margin-top: 0;
  margin-left: 16px;
}

.pfad-14-4 {
  height: 116px;
  object-fit: cover;
  width: 90px;
  align-self: flex-end;
  margin-left: 18px;
}

.pfad-1-5 {
  height: 114px;
  object-fit: cover;
  width: 89px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 18px;
}

.gruppe-33.gruppe-34 {
  height: unset;
  left: unset;
  min-width: 490.18px;
  opacity: .3;
  position: unset;
  top: unset;
  flex: 1;
}

.benutzerdefiniertes-format-1 {
  background-color: var(--white);
  height: 3240px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.overlap-group9 {
  height: 3498px;
  width: 100%;
  margin-top: -242px;
  margin-left: -412px;
  position: relative;
}

.overlap-group8 {
  height: 1375px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rechteck-21 {
  height: 1080px;
  width: 100%;
  background: linear-gradient(#1900bb 0%, #05031e 100%);
  position: absolute;
  top: 242px;
  left: 412px;
}

.rechteck-21 video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.rechteck-21 img {
  object-fit: cover;
}

.rechteck-22 {
  height: 99px;
  width: 1920px;
  background: linear-gradient(#0d0081 0%, #821de8 5.02%, #212192 50.63%, #9c1cff 92.05%, #b810ff 100%);
  position: absolute;
  top: 231px;
  left: 412px;
}

.ellipse-5 {
  height: 584px;
  object-fit: cover;
  width: 781px;
  border-radius: 390.5px / 292px;
  position: absolute;
  top: 11px;
  left: 1914px;
}

.ellipse-3 {
  height: 584px;
  object-fit: cover;
  width: 781px;
  border-radius: 390.5px / 292px;
  position: absolute;
  top: 0;
  left: 974px;
}

.rechteck-18 {
  height: 72px;
  width: 1640px;
  background: linear-gradient(92.1deg, #ffffff59 0%, #ffffff26 99.97%);
  border: 2px solid #ffffff38;
  border-radius: 20px;
  position: absolute;
  top: 257px;
  left: 552px;
  box-shadow: 0 5px 2px #00000029;
}

.telegram {
  height: 60px;
  object-fit: cover;
  width: 51px;
  position: absolute;
  top: 267px;
  left: 1895px;
}

.discord {
  height: 59px;
  object-fit: cover;
  width: 51px;
  position: absolute;
  top: 268px;
  left: 1960px;
}

.telegram-1 {
  height: 63px;
  object-fit: cover;
  width: 47px;
  position: absolute;
  top: 266px;
  left: 2025px;
}

.about {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 283px;
  left: 764px;
}

.team {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 283px;
  left: 1183px;
}

.rechteck-1 {
  border: 2px solid;
  border-color: var(--white);
  height: 32px;
  width: 123px;
  border-radius: 16px;
  position: absolute;
  top: 277px;
  left: 1553px;
  box-shadow: 0 5px 2px #00000029;
}

.rechteck-2 {
  border: 2px solid;
  border-color: var(--white);
  height: 32px;
  width: 123px;
  border-radius: 16px;
  position: absolute;
  top: 277px;
  left: 1711px;
  box-shadow: 0 5px 2px #00000029;
}

.claim {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 285px;
  left: 1590px;
}

.connect {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 285px;
  left: 1733px;
}

.ellipse-1 {
  height: 1118px;
  object-fit: cover;
  width: 1422px;
  border-radius: 711px / 559px;
  position: absolute;
  top: 257px;
  left: 193px;
}

.ellipse-2 {
  height: 923px;
  object-fit: cover;
  width: 933px;
  border-radius: 466.5px / 461.5px;
  position: absolute;
  top: 355px;
  left: 508px;
}

.all {
  cursor: pointer;
  height: 868px;
  object-fit: cover;
  width: 1008px;
  transition: all .2s;
  position: absolute;
  top: 408px;
  left: 1274px;
}

.all:hover {
  transform: scale(1.1);
}

.unbenannt-2 {
  height: 187px;
  width: 539px;
  display: flex;
  position: absolute;
  top: 551px;
  left: 663px;
}

.ebene-1 {
  width: 539px;
  flex: 1;
  display: flex;
}

.gruppe-37 {
  min-width: 539px;
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.overlap-group3 {
  height: 187px;
  width: 539px;
  position: relative;
}

.gruppe-18 {
  height: 187px;
  width: 539px;
  background-position: 50%;
  background-size: cover;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.gruppe-17 {
  height: 143.25px;
  min-width: calc(100% - 43px);
  flex: 1;
  align-items: flex-end;
  margin-top: 14.7px;
  margin-left: 21.6px;
  margin-right: 21.5px;
  display: flex;
}

.gruppe-container {
  height: 143px;
  width: 496px;
  margin-bottom: 0;
  position: relative;
}

.gruppe-1 {
  height: 122px;
  min-width: 467px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 22px;
  left: 14px;
}

.pfad-1-2 {
  height: 120px;
  object-fit: cover;
  width: 84px;
  align-self: flex-start;
}

.pfad-2-1 {
  height: 119px;
  object-fit: cover;
  width: 69px;
  margin-bottom: 0;
  margin-left: 8px;
}

.pfad {
  height: 119px;
  object-fit: cover;
  width: 94px;
  margin-bottom: .01px;
  margin-left: 10px;
}

.pfad-4-2 {
  height: 122px;
  object-fit: cover;
  width: 90px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.pfad-1 {
  height: 120px;
  object-fit: cover;
  width: 90px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-2 {
  height: 122px;
  min-width: 469px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 20px;
  left: 14px;
}

.pfad-6-2 {
  height: 120px;
  object-fit: cover;
  width: 84px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-7-1 {
  height: 119px;
  object-fit: cover;
  width: 69px;
  margin-bottom: .01px;
  margin-left: 8px;
}

.pfad-2 {
  height: 122px;
  object-fit: cover;
  width: 91px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-3 {
  height: 122px;
  min-width: 471px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 19px;
  left: 13px;
}

.pfad-3 {
  height: 120px;
  object-fit: cover;
  width: 85px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-4 {
  height: 119px;
  object-fit: cover;
  width: 70px;
  margin-bottom: 0;
  margin-left: 8px;
}

.gruppe-4 {
  height: 122px;
  min-width: 473px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 17px;
  left: 12px;
}

.pfad-16-1 {
  height: 120px;
  object-fit: cover;
  width: 85px;
  align-self: flex-start;
}

.pfad-17-1 {
  height: 119px;
  object-fit: cover;
  width: 70px;
  margin-bottom: .01px;
  margin-left: 8px;
}

.pfad-5 {
  height: 119px;
  object-fit: cover;
  width: 95px;
  margin-bottom: .01px;
  margin-left: 10px;
}

.pfad-6 {
  height: 120px;
  object-fit: cover;
  width: 91px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-5 {
  height: 122px;
  min-width: 475px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 16px;
  left: 11px;
}

.pfad-24-1 {
  height: 122px;
  object-fit: cover;
  width: 92px;
  margin-top: 0;
  margin-left: 12px;
}

.gruppe-6 {
  height: 122px;
  min-width: 476px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 14px;
  left: 10px;
}

.pfad-7 {
  height: 120px;
  object-fit: cover;
  width: 86px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-8 {
  height: 119px;
  object-fit: cover;
  width: 71px;
  margin-bottom: .01px;
  margin-left: 8px;
}

.pfad-9 {
  height: 122px;
  object-fit: cover;
  width: 92px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-7 {
  height: 122px;
  min-width: 478px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 13px;
  left: 9px;
}

.pfad-3-1 {
  height: 119px;
  object-fit: cover;
  width: 96px;
  margin-bottom: .01px;
  margin-left: 10px;
}

.pfad-10 {
  height: 120px;
  object-fit: cover;
  width: 92px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-8 {
  height: 122px;
  min-width: 480px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 11px;
  left: 8px;
}

.pfad-37 {
  height: 119px;
  object-fit: cover;
  width: 71px;
  margin-bottom: 0;
  margin-left: 8px;
}

.pfad-11 {
  height: 122px;
  object-fit: cover;
  width: 93px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-9 {
  height: 122px;
  min-width: 482px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 10px;
  left: 7px;
}

.pfad-4-1 {
  height: 120px;
  object-fit: cover;
  width: 87px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-12 {
  height: 119px;
  object-fit: cover;
  width: 97px;
  margin-bottom: .01px;
  margin-left: 10px;
}

.pfad-13 {
  height: 120px;
  object-fit: cover;
  width: 93px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-10 {
  height: 122px;
  min-width: 484px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 9px;
  left: 6px;
}

.pfad-14 {
  height: 119px;
  object-fit: cover;
  width: 72px;
  margin-bottom: 0;
  margin-left: 8px;
}

.pfad-49 {
  height: 122px;
  object-fit: cover;
  width: 94px;
  margin-top: 0;
  margin-left: 12px;
}

.gruppe-11 {
  height: 122px;
  min-width: 486px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 7px;
  left: 5px;
}

.pfad-15 {
  height: 120px;
  object-fit: cover;
  width: 88px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-52 {
  height: 119px;
  object-fit: cover;
  width: 72px;
  margin-bottom: .01px;
  margin-left: 8px;
}

.pfad-5-1 {
  height: 122px;
  object-fit: cover;
  width: 94px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-12 {
  height: 122px;
  min-width: 488px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 6px;
  left: 4px;
}

.pfad-16 {
  height: 119px;
  object-fit: cover;
  width: 98px;
  margin-bottom: .01px;
  margin-left: 10px;
}

.pfad-17 {
  height: 120px;
  object-fit: cover;
  width: 94px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-13 {
  height: 122px;
  min-width: 490px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 4px;
  left: 3px;
}

.pfad-18 {
  height: 119px;
  object-fit: cover;
  width: 73px;
  margin-bottom: .01px;
  margin-left: 8px;
}

.pfad-6-1 {
  height: 122px;
  object-fit: cover;
  width: 95px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-14 {
  height: 122px;
  min-width: 492px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 3px;
  left: 2px;
}

.pfad-19 {
  height: 120px;
  object-fit: cover;
  width: 89px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-20 {
  height: 119px;
  object-fit: cover;
  width: 99px;
  margin-bottom: .01px;
  margin-left: 10px;
}

.gruppe-15 {
  height: 122px;
  min-width: 494px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1px;
  left: 1px;
}

.pfad-72 {
  height: 119px;
  object-fit: cover;
  width: 73px;
  margin-bottom: 0;
  margin-left: 8px;
}

.pfad-74 {
  height: 122px;
  object-fit: cover;
  width: 95px;
  margin-top: 0;
  margin-left: 12px;
}

.pfad-21 {
  height: 120px;
  object-fit: cover;
  width: 95px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-16 {
  height: 122px;
  min-width: 496px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pfad-76 {
  height: 120px;
  object-fit: cover;
  width: 89px;
  align-self: flex-start;
}

.pfad-79 {
  height: 122px;
  object-fit: cover;
  width: 96px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 12px;
}

.gruppe-container-1 {
  height: 127px;
  width: 493px;
  position: absolute;
  top: 16px;
  left: 23px;
}

.gruppe-19 {
  height: 119px;
  min-width: 483px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 9px;
  left: 5px;
}

.pfad-22 {
  height: 117px;
  object-fit: cover;
  width: 85px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-82 {
  height: 116px;
  object-fit: cover;
  width: 69px;
  margin-bottom: 0;
  margin-left: 11px;
}

.pfad-23 {
  height: 116px;
  object-fit: cover;
  width: 95px;
  margin-top: 0;
  margin-left: 13px;
}

.pfad-8-1 {
  height: 119px;
  object-fit: cover;
  width: 91px;
  margin-left: 15px;
}

.pfad-85 {
  height: 117px;
  object-fit: cover;
  width: 90px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 15px;
}

.gruppe-20 {
  height: 119px;
  min-width: 484px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 8px;
  left: 4px;
}

.pfad-24 {
  height: 117px;
  object-fit: cover;
  width: 85px;
  align-self: flex-start;
  margin-top: .01px;
}

.pfad-87 {
  height: 116px;
  object-fit: cover;
  width: 69px;
  margin-top: .01px;
  margin-left: 11px;
}

.pfad-25 {
  height: 116px;
  object-fit: cover;
  width: 95px;
  margin-top: .01px;
  margin-left: 13px;
}

.pfad-26 {
  height: 117px;
  object-fit: cover;
  width: 91px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 15px;
}

.gruppe-21 {
  height: 119px;
  min-width: 485px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 7px;
  left: 4px;
}

.pfad-91 {
  height: 117px;
  object-fit: cover;
  width: 85px;
  margin-top: 0;
}

.pfad-92 {
  height: 116px;
  object-fit: cover;
  width: 69px;
  align-self: center;
  margin-bottom: 0;
  margin-left: 11px;
}

.pfad-27 {
  height: 116px;
  object-fit: cover;
  width: 95px;
  align-self: center;
  margin-top: 0;
  margin-left: 13px;
}

.pfad-94 {
  height: 119px;
  object-fit: cover;
  width: 91px;
  margin-top: 0;
  margin-left: 15px;
}

.gruppe-22 {
  height: 119px;
  min-width: 486px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 6px;
  left: 3px;
}

.pfad-97 {
  height: 116px;
  object-fit: cover;
  width: 70px;
  margin-left: 11px;
}

.pfad-28 {
  height: 119px;
  object-fit: cover;
  width: 92px;
  margin-left: 15px;
}

.gruppe-23 {
  height: 119px;
  min-width: 487px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 5px;
  left: 3px;
}

.pfad-102 {
  height: 116px;
  object-fit: cover;
  width: 70px;
  margin-top: .01px;
  margin-left: 11px;
}

.gruppe-24 {
  height: 119px;
  min-width: 488px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 4px;
  left: 2px;
}

.pfad-106 {
  height: 117px;
  object-fit: cover;
  width: 86px;
  margin-top: 0;
}

.pfad-107 {
  height: 116px;
  object-fit: cover;
  width: 70px;
  align-self: center;
  margin-bottom: 0;
  margin-left: 11px;
}

.pfad-109 {
  height: 119px;
  object-fit: cover;
  width: 92px;
  margin-top: 0;
  margin-left: 15px;
}

.gruppe-27 {
  height: 119px;
  min-width: 491px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 2px;
  left: 1px;
}

.pfad-12-1 {
  height: 117px;
  object-fit: cover;
  width: 86px;
  align-self: flex-start;
  margin-top: 0;
}

.pfad-122 {
  height: 116px;
  object-fit: cover;
  width: 70px;
  margin-bottom: 0;
  margin-left: 11px;
}

.pfad-12-2 {
  height: 116px;
  object-fit: cover;
  width: 96px;
  margin-top: 0;
  margin-left: 13px;
}

.pfad-12-3 {
  height: 119px;
  object-fit: cover;
  width: 93px;
  margin-left: 15px;
}

.pfad-1-1 {
  height: 117px;
  object-fit: cover;
  width: 92px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 15px;
}

.gruppe-28 {
  height: 119px;
  min-width: 492px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1px;
  left: 0;
}

.pfad-127 {
  height: 116px;
  object-fit: cover;
  width: 70px;
  margin-top: 0;
  margin-left: 11px;
}

.gruppe-32 {
  height: 137px;
  width: 511px;
  background-position: 50%;
  background-size: cover;
  display: flex;
  position: absolute;
  top: 13px;
  left: 16px;
}

.gruppe-container-2 {
  height: 116px;
  width: 490px;
  position: absolute;
  top: 18px;
  left: 24px;
}

.gruppe-35 {
  height: 116px;
  width: 490px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-updated {
  height: 78px;
  object-fit: cover;
  width: 76px;
  position: absolute;
  top: 259px;
  left: 1334px;
}

.place {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 283px;
  left: 643px;
}

.roadmap {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 283px;
  left: 891px;
}

.how-to {
  letter-spacing: 0;
  text-shadow: 0 5px 2px #00000029;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 283px;
  left: 1044px;
}

.icon-instagram {
  height: 61px;
  object-fit: cover;
  width: 56px;
  position: absolute;
  top: 267px;
  left: 2086px;
}

.overlap-group5 {
  height: 338px;
  width: 650px;
  position: absolute;
  top: 755px;
  left: 602px;
}

.gruppe-48 {
  height: 124px;
  min-width: 650px;
  background-position: 50%;
  background-size: cover;
  align-items: flex-end;
  padding: 3.8px 5.2px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group1 {
  height: 115px;
  width: 636px;
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.pfad-181 {
  height: 77px;
  object-fit: cover;
  width: 26px;
  position: absolute;
  top: 28px;
  left: 0;
}

.pfad-182 {
  height: 8px;
  object-fit: cover;
  width: 16px;
  position: absolute;
  top: 52px;
  left: 219px;
}

.pfad-183 {
  height: 25px;
  object-fit: cover;
  width: 529px;
  position: absolute;
  top: 91px;
  left: 54px;
}

.pfad-184 {
  height: 10px;
  object-fit: cover;
  width: 21px;
  position: absolute;
  top: 37px;
  left: 359px;
}

.gruppe-45 {
  height: 9px;
  min-width: 19px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 37px;
  left: 358px;
}

.pfad-187 {
  height: 9px;
  object-fit: cover;
  width: 18px;
}

.overlap-group {
  min-width: 1px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 3.31px;
  display: flex;
}

.pfad-186 {
  height: 1px;
  object-fit: cover;
  width: 1px;
  margin-bottom: -.03px;
}

.pfad-188 {
  height: 31px;
  object-fit: cover;
  width: 28px;
  position: absolute;
  top: 46px;
  left: 608px;
}

.pfad-189 {
  height: 87px;
  object-fit: cover;
  width: 591px;
  position: absolute;
  top: 14px;
  left: 20px;
}

.pfad-190 {
  height: 3px;
  object-fit: cover;
  width: 228px;
  position: absolute;
  top: 13px;
  left: 159px;
}

.pfad-191 {
  height: 3px;
  object-fit: cover;
  width: 215px;
  position: absolute;
  top: 91px;
  left: 190px;
}

.pfad-192 {
  height: 50px;
  object-fit: cover;
  width: 93px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.pfad-193 {
  height: 22px;
  object-fit: cover;
  width: 104px;
  position: absolute;
  top: 80px;
  left: 23px;
}

.pfad-194 {
  height: 42px;
  object-fit: cover;
  width: 268px;
  position: absolute;
  top: 33px;
  left: 38px;
}

.gruppe-46 {
  height: 115px;
  mix-blend-mode: overlay;
  width: 353px;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.pfad-196 {
  height: 11px;
  object-fit: cover;
  width: 20px;
  position: absolute;
  top: 62px;
  left: 312px;
}

.gruppe-47 {
  height: 97px;
  mix-blend-mode: overlay;
  width: 132px;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 3px;
  left: 455px;
}

.pfad-198 {
  height: 40px;
  object-fit: cover;
  width: 19px;
  position: absolute;
  top: 19px;
  left: 465px;
}

.pfad-199 {
  height: 25px;
  object-fit: cover;
  width: 57px;
  position: absolute;
  top: 55px;
  left: 122px;
}

.pfad-200 {
  height: 67px;
  object-fit: cover;
  width: 28px;
  position: absolute;
  top: 30px;
  left: 608px;
}

.pfad-201 {
  height: 36px;
  object-fit: cover;
  width: 181px;
  position: absolute;
  top: 18px;
  left: 432px;
}

.pfad-202 {
  height: 38px;
  object-fit: cover;
  width: 95px;
  position: absolute;
  top: 64px;
  left: 513px;
}

.pfad-203 {
  height: 34px;
  object-fit: cover;
  width: 18px;
  position: absolute;
  top: 21px;
  left: 279px;
}

.pfad-204 {
  height: 40px;
  object-fit: cover;
  width: 18px;
  position: absolute;
  top: 16px;
  left: 276px;
}

.pfad-205 {
  height: 17px;
  object-fit: cover;
  width: 29px;
  position: absolute;
  top: 58px;
  left: 111px;
}

.pfad-206 {
  height: 29px;
  object-fit: cover;
  width: 8px;
  position: absolute;
  top: 32px;
  left: 558px;
}

.pfad-207 {
  height: 8px;
  object-fit: cover;
  width: 10px;
  position: absolute;
  top: 65px;
  left: 322px;
}

.gruppe-52 {
  height: 124px;
  min-width: 627px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 2.4px 6.4px;
  display: flex;
  position: absolute;
  top: 107px;
  left: 14px;
}

.overlap-group2 {
  height: 119px;
  width: 612px;
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.pfad-210 {
  height: 50px;
  object-fit: cover;
  width: 203px;
  position: absolute;
  top: 39px;
  left: 44px;
}

.pfad-211 {
  height: 78px;
  object-fit: cover;
  width: 37px;
  position: absolute;
  top: 31px;
  left: 576px;
}

.pfad-212 {
  height: 8px;
  object-fit: cover;
  width: 16px;
  position: absolute;
  top: 49px;
  left: 386px;
}

.pfad-213 {
  height: 27px;
  object-fit: cover;
  width: 543px;
  position: absolute;
  top: 0;
  left: 35px;
}

.pfad-214 {
  height: 10px;
  object-fit: cover;
  width: 19px;
  position: absolute;
  top: 60px;
  left: 248px;
}

.pfad-container {
  height: 9px;
  width: 18px;
  position: absolute;
  top: 62px;
  left: 250px;
}

.pfad-215 {
  height: 1px;
  object-fit: cover;
  width: 1px;
  position: absolute;
  top: 7px;
  left: 1px;
}

.pfad-216 {
  height: 1px;
  object-fit: cover;
  width: 1px;
  position: absolute;
  top: 7px;
  left: 0;
}

.pfad-217 {
  height: 9px;
  object-fit: cover;
  width: 17px;
  position: absolute;
  top: 0;
  left: 1px;
}

.pfad-218 {
  height: 33px;
  object-fit: cover;
  width: 29px;
  position: absolute;
  top: 60px;
  left: 3px;
}

.pfad-219 {
  height: 95px;
  object-fit: cover;
  width: 576px;
  position: absolute;
  top: 14px;
  left: 20px;
}

.pfad-220 {
  height: 10px;
  object-fit: cover;
  width: 206px;
  position: absolute;
  top: 91px;
  left: 243px;
}

.pfad-221 {
  height: 6px;
  object-fit: cover;
  width: 218px;
  position: absolute;
  top: 11px;
  left: 218px;
}

.pfad-222 {
  height: 41px;
  object-fit: cover;
  width: 98px;
  position: absolute;
  top: 70px;
  left: 494px;
}

.pfad-223 {
  height: 34px;
  object-fit: cover;
  width: 96px;
  position: absolute;
  top: 21px;
  left: 500px;
}

.pfad-224 {
  height: 41px;
  object-fit: cover;
  width: 255px;
  position: absolute;
  top: 55px;
  left: 317px;
}

.gruppe-50 {
  height: 119px;
  mix-blend-mode: overlay;
  width: 337px;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 275px;
}

.pfad-226 {
  height: 12px;
  object-fit: cover;
  width: 19px;
  position: absolute;
  top: 34px;
  left: 207px;
}

.gruppe-51 {
  height: 99px;
  mix-blend-mode: overlay;
  width: 117px;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 20px;
  left: 67px;
}

.pfad-228 {
  height: 39px;
  object-fit: cover;
  width: 26px;
  position: absolute;
  top: 56px;
  left: 147px;
}

.pfad-229 {
  height: 68px;
  object-fit: cover;
  width: 34px;
  position: absolute;
  top: 38px;
  left: 0;
}

.pfad-230 {
  height: 27px;
  object-fit: cover;
  width: 171px;
  position: absolute;
  top: 79px;
  left: 32px;
}

.pfad-231 {
  height: 49px;
  object-fit: cover;
  width: 91px;
  position: absolute;
  top: 20px;
  left: 17px;
}

.pfad-232 {
  height: 35px;
  object-fit: cover;
  width: 18px;
  position: absolute;
  top: 52px;
  left: 326px;
}

.pfad-233 {
  height: 42px;
  object-fit: cover;
  width: 18px;
  position: absolute;
  top: 50px;
  left: 329px;
}

.pfad-234 {
  height: 16px;
  object-fit: cover;
  width: 30px;
  position: absolute;
  top: 44px;
  left: 480px;
}

.pfad-235 {
  height: 30px;
  object-fit: cover;
  width: 9px;
  position: absolute;
  top: 63px;
  left: 73px;
}

.pfad-236 {
  height: 9px;
  object-fit: cover;
  width: 10px;
  position: absolute;
  top: 34px;
  left: 206px;
}

.overlap-group3-1 {
  height: 120px;
  width: 541px;
  position: absolute;
  top: 217px;
  left: 57px;
}

.gruppe-53 {
  height: 120px;
  width: 541px;
  background-position: 50%;
  background-size: cover;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pfad-238 {
  object-fit: cover;
  width: 525.85px;
  flex: 1;
  margin: 5.2px 7.8px 5.7px 7.6px;
}

.pfad-239 {
  height: 23px;
  object-fit: cover;
  width: 434px;
  position: absolute;
  top: 91px;
  left: 55px;
}

.pfad-240 {
  height: 64px;
  object-fit: cover;
  width: 29px;
  position: absolute;
  top: 34px;
  left: 504px;
}

.pfad-241 {
  height: 44px;
  object-fit: cover;
  width: 271px;
  position: absolute;
  top: 16px;
  left: 241px;
}

.pfad-242 {
  height: 32px;
  object-fit: cover;
  width: 319px;
  position: absolute;
  top: 70px;
  left: 187px;
}

.pfad-243 {
  height: 58px;
  object-fit: cover;
  width: 31px;
  position: absolute;
  top: 41px;
  left: 9px;
}

.pfad-244 {
  height: 57px;
  object-fit: cover;
  width: 45px;
  position: absolute;
  top: 23px;
  left: 32px;
}

.pfad-245 {
  height: 18px;
  object-fit: cover;
  width: 109px;
  position: absolute;
  top: 86px;
  left: 40px;
}

.pfad-246 {
  height: 75px;
  object-fit: cover;
  width: 427px;
  position: absolute;
  top: 22px;
  left: 64px;
}

.pfad-247 {
  height: 109px;
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 283px;
  position: absolute;
  top: 5px;
  left: 8px;
}

.pfad-248 {
  height: 107px;
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 193px;
  position: absolute;
  top: 7px;
  left: 341px;
}

.pfad-249 {
  height: 49px;
  object-fit: cover;
  width: 32px;
  position: absolute;
  top: 13px;
  left: 181px;
}

.pfad-250 {
  height: 51px;
  object-fit: cover;
  width: 38px;
  position: absolute;
  top: 35px;
  left: 340px;
}

.pfad-251 {
  height: 8px;
  object-fit: cover;
  width: 14px;
  position: absolute;
  top: 43px;
  left: 260px;
}

.pfad-252 {
  height: 10px;
  object-fit: cover;
  width: 22px;
  position: absolute;
  top: 45px;
  left: 470px;
}

.pfad-253 {
  height: 7px;
  object-fit: cover;
  width: 18px;
  position: absolute;
  top: 60px;
  left: 204px;
}

.pfad-254 {
  height: 7px;
  object-fit: cover;
  width: 11px;
  position: absolute;
  top: 60px;
  left: 204px;
}

.pfad-container-1 {
  height: 283px;
  width: 484px;
  position: absolute;
  top: 32px;
  left: 79px;
}

.pfad-255 {
  height: 282px;
  mix-blend-mode: multiply;
  object-fit: cover;
  width: 419px;
  position: absolute;
  top: 1px;
  left: 65px;
}

.pfad-256 {
  height: 273px;
  object-fit: cover;
  width: 484px;
  position: absolute;
  top: 0;
  left: 0;
}

.pfad-257 {
  height: 34px;
  object-fit: cover;
  width: 70px;
  position: absolute;
  top: 1px;
  left: 413px;
}

.pfad-258 {
  height: 22px;
  object-fit: cover;
  width: 44px;
  position: absolute;
  top: 14px;
  left: 0;
}

.pfad-259 {
  height: 273px;
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.pengu-combines-a-def {
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 5px 2px #00000029;
  width: 422px;
  line-height: 17px;
  position: absolute;
  top: 837px;
  left: 713px;
}

.overlap-group6 {
  height: 49px;
  width: 150px;
  position: absolute;
  top: 992px;
  left: 758px;
}

.gruppe-41 {
  height: 49px;
  width: 150px;
  background-position: 50%;
  background-size: cover;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.rechteck-20 {
  background-color: var(--blizzard-blue);
  width: 145.6px;
  border-radius: 12.41px;
  flex: 1;
  margin: 2.4px 2.5px 3px 2.3px;
}

.pfad-162 {
  height: 6px;
  object-fit: cover;
  width: 127px;
  position: absolute;
  top: 40px;
  left: 12px;
}

.pfad-163 {
  height: 28px;
  object-fit: cover;
  width: 6px;
  position: absolute;
  top: 10px;
  left: 142px;
}

.pfad-164 {
  height: 14px;
  object-fit: cover;
  width: 75px;
  position: absolute;
  top: 7px;
  left: 67px;
}

.pfad-165 {
  height: 16px;
  object-fit: cover;
  width: 92px;
  position: absolute;
  top: 26px;
  left: 51px;
}

.pfad-166 {
  height: 25px;
  object-fit: cover;
  width: 8px;
  position: absolute;
  top: 13px;
  left: 2px;
}

.pfad-167 {
  height: 23px;
  object-fit: cover;
  width: 13px;
  position: absolute;
  top: 7px;
  left: 9px;
}

.pfad-168 {
  height: 9px;
  object-fit: cover;
  width: 31px;
  position: absolute;
  top: 33px;
  left: 9px;
}

.pfad-169 {
  height: 30px;
  object-fit: cover;
  width: 122px;
  position: absolute;
  top: 9px;
  left: 16px;
}

.pfad-170 {
  height: 43px;
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 78px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.pfad-171 {
  height: 43px;
  mix-blend-mode: overlay;
  object-fit: cover;
  width: 53px;
  position: absolute;
  top: 2px;
  left: 95px;
}

.pfad-172 {
  height: 21px;
  object-fit: cover;
  width: 10px;
  position: absolute;
  top: 6px;
  left: 50px;
}

.pfad-173 {
  height: 22px;
  object-fit: cover;
  width: 11px;
  position: absolute;
  top: 15px;
  left: 95px;
}

.pfad-174 {
  height: 4px;
  object-fit: cover;
  width: 4px;
  position: absolute;
  top: 19px;
  left: 72px;
}

.pfad-175 {
  height: 4px;
  object-fit: cover;
  width: 6px;
  position: absolute;
  top: 16px;
  left: 131px;
}

.pfad-176 {
  height: 3px;
  object-fit: cover;
  width: 5px;
  position: absolute;
  top: 26px;
  left: 56px;
}

.pfad-177 {
  height: 3px;
  object-fit: cover;
  width: 3px;
  position: absolute;
  top: 26px;
  left: 56px;
}

.overlap-group7 {
  height: 49px;
  width: 150px;
  position: absolute;
  top: 992px;
  left: 932px;
}

.buy {
  -webkit-text-stroke: 1px var(--navy-blue);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 25px;
  position: absolute;
  top: 1004px;
  left: 808px;
}

.how-to-1 {
  -webkit-text-stroke: 1px var(--navy-blue);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 25px;
  position: absolute;
  top: 1004px;
  left: 956px;
}

.ellipse-4 {
  height: 584px;
  object-fit: cover;
  width: 781px;
  border-radius: 390.5px / 292px;
  position: absolute;
  top: 11px;
  left: 0;
}

.overlap-group9-item {
  height: 1080px;
  object-fit: cover;
  width: 1920px;
  position: absolute;
  top: 1321px;
  left: 412px;
}

.bg-pengu-2-aurora {
  height: 1080px;
  object-fit: cover;
  width: 1920px;
  position: absolute;
  top: 1320px;
  left: 412px;
}

.rechteck-23 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  border: .2px solid;
  border-color: var(--white);
  height: 364px;
  width: 812px;
  background: linear-gradient(114.15deg, #fff3 0%, #ffffff0d 100%);
  border-radius: 27px;
  position: absolute;
  top: 1455px;
  left: 552px;
}

.pengu-combines-a-def-1 {
  height: 221px;
  letter-spacing: 0;
  width: 735px;
  line-height: 17px;
  position: absolute;
  top: 1570px;
  left: 594px;
}

.x5 {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 17px;
  position: absolute;
  top: 1376px;
  left: 594px;
}

.title {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 600;
  line-height: 40px;
  position: absolute;
  top: 1498px;
  left: 594px;
}

.rechteck-24 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  border: .2px solid;
  border-color: var(--white);
  height: 193px;
  width: 398px;
  background: linear-gradient(115.87deg, #fff3 0%, #ffffff0d 100%);
  border-radius: 27px;
  position: absolute;
  top: 1838px;
  left: 552px;
}

.rechteck-25 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  border: .2px solid;
  border-color: var(--white);
  height: 193px;
  width: 398px;
  background: linear-gradient(115.87deg, #fff3 0%, #ffffff0d 100%);
  border-radius: 27px;
  position: absolute;
  top: 1838px;
  left: 966px;
}

.rechteck-26 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  border: .2px solid;
  border-color: var(--white);
  height: 193px;
  width: 398px;
  background: linear-gradient(115.87deg, #fff3 0%, #ffffff0d 100%);
  border-radius: 27px;
  position: absolute;
  top: 2050px;
  left: 552px;
}

.rechteck-27 {
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  -webkit-backdrop-filter: blur(7px) brightness(115%);
  backdrop-filter: blur(7px) brightness(115%);
  border: .2px solid;
  border-color: var(--white);
  height: 193px;
  width: 398px;
  background: linear-gradient(115.87deg, #fff3 0%, #ffffff0d 100%);
  border-radius: 27px;
  position: absolute;
  top: 2050px;
  left: 966px;
}

.x015-of-the-transac {
  height: 148px;
  letter-spacing: 0;
  width: 342px;
  line-height: 17px;
  position: absolute;
  top: 1861px;
  left: 581px;
}

.x015-of-the-transac-1 {
  height: 148px;
  letter-spacing: 0;
  width: 342px;
  line-height: 17px;
  position: absolute;
  top: 1861px;
  left: 995px;
}

.x010-of-the-transac {
  height: 148px;
  letter-spacing: 0;
  width: 342px;
  line-height: 17px;
  position: absolute;
  top: 2078px;
  left: 581px;
}

.x010-of-the-transac-1 {
  height: 148px;
  letter-spacing: 0;
  width: 342px;
  line-height: 17px;
  position: absolute;
  top: 2074px;
  left: 995px;
}

.igloo-1 {
  height: 832px;
  object-fit: cover;
  width: 1479px;
  position: absolute;
  top: 1474px;
  left: 853px;
}

.all-1 {
  height: 736px;
  object-fit: cover;
  width: 968px;
  position: absolute;
  top: 1546px;
  left: 1177px;
}

.bg-pengu-3-bg {
  height: 1098px;
  object-fit: cover;
  width: 1920px;
  position: absolute;
  top: 2400px;
  left: 396px;
}

.bg-pengu-3-iceberg {
  height: 1080px;
  object-fit: cover;
  width: 1920px;
  position: absolute;
  top: 2400px;
  left: 412px;
}

/*# sourceMappingURL=index.ce25fb23.css.map */
